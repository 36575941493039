@import '../../styles/style'
    
.range
    &__wrapper 
        width: 250px
    &__title
        text-align: center
        font-size: 18px
        color: silver
    &__value 
        width: 100%
        display: flex
        justify-content: space-between
        margin: 8px 0 20px
        &_input
            width: 110px
            height: 100%
            outline: none
            font-size: 19px
            text-align: left
            padding: 0 5px
            background: $black3-color
            color: whitesmoke
            border-radius: 5px
            border: 1px solid $black3-color
            -moz-appearance: none
            &-max
                text-align: right
        &_input[type="number"]
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button 
                -webkit-appearance: none
    &__slider 
        height: 5px
        border-radius: 5px
        background: $yellow0-color
        position: relative
        &_progress 
            height: 5px
            left: 25%
            right: 25%
            position: absolute
            border-radius: 5px
            background: $yellow3-color
    &__range
        position: relative
        &_input 
            position: absolute
            top: -5px
            height: 5px
            width: 100%
            background: none
            pointer-events: none
            -webkit-appearance: none
        &_input[type="range"]::-webkit-slider-thumb
            height: 17px
            width: 17px
            border-radius: 50%
            pointer-events: auto
            -webkit-appearance: none
            background: $yellow3-color
            cursor: pointer
        &_input[type="range"]::-moz-slider-thumb
            height: 17px
            width: 17px
            border-radius: 50%
            pointer-events: auto
            -webkit-appearance: none
            background: $yellow3-color
    &__btns
        display: flex
        flex-direction: row
        justify-content: start
        margin-top: 10px
        color: $yellow0-color
        font-size: 14px
        &_btn
            background: $black3-color
            padding: 0 2px
            border-radius: 7px
            margin-right: 12px
            &:hover
                cursor: pointer
                background: none
