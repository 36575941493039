@import '../../styles/style.sass'
    
.flat-card
    margin: 20px auto
    color: $black2-color
    padding: 10px 15px
    height: 200px
    width: 650px
    border-radius: 15px
    transition: all 0.8s
    &:hover
        cursor: pointer
        border-radius: 30px
        box-shadow: 15px 10px 7px #03052d
        border-color: $black2-color!important
    &.hover-second
        background-color: $yellow0-color
    &.hover-first
        background-color: $blue1-color
    &__date
        font-size: 12px
        line-height: 16px
        font-style: italic
        color: $black5-color
        padding-left: 20px
    &__wrapper
        display: grid
        grid-template-columns: 200px 250px 170px
    &__left
        img
            width: 100%
            height: 160px
            object-fit: contain
    &__middle
        padding-left: 50px
        font-size: 18px
        color: $black5-color
        &_flat
            font-size: 20px
            font-weight: bold
            color: $black2-color
            display: inline
        &_region
            white-space: nowrap
    &__right
        text-align: right
        &_price
            font-size: 20px
            font-weight: bold
        &_subprice
            font-size: 14px
            color: $black5-color


@media (max-width: 675px)
    .flat-card
        width: 450px
        height: 160px
        padding: 8px
        &__wrapper
            grid-template-columns: 140px 160px 130px
        &__middle
            padding-left: 15px
            font-size: 14px
            &_flat
                font-size: 16px
        &__right
            &_price
                font-size: 18px
            &_subprice
                font-size: 12px

@media (max-width: 500px)
    .flat-card
        width: 95%
        &__wrapper
            grid-template-columns: 30% 40% 30%
        &__middle
            padding-left: 10px
            font-size: 12px
            &_flat
                font-size: 14px
        &__right
            &_price
                font-size: 16px
            &_subprice
                font-size: 12px

@media (max-width: 425px)
    .flat-card
        padding-left: 2px
        padding-right: 4px
        &__wrapper
            grid-template-columns: 26% 42% 32%
        &__middle
            padding-left: 9px