@import '../../styles/style.sass'
    
.pageup
    width: 40px //62px
    height: 40px //62px
    position: fixed
    bottom: 96px
    right: 81px
    z-index: 100
    &:hover
        svg 
            path
                fill: $yellow1-color
                opasity: unset