.list
    width: 70%
    margin: 25px auto
    &__empty
        margin-top: 30px
        text-align: center
        font-size: 20px


@media ( max-width: 950px )
    .list
        width: 100%

@media ( max-width: 850px )
    .list
        margin-top: 30px
        &__empty
            margin-top: 70px