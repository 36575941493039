.account
    margin: 80px auto 0
    width: 90%
    &__tabs
        margin: 0 auto
    &__btn
        position: fixed
        left: 30px
        bottom: 30px

@media (max-width: 500px)
    .account
        width: 100%