@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css')
@import 'bootstrap-icons/font/bootstrap-icons.css'
@import './custom.sass'


$gray1-color: #EAECEE
$gray2-color: #E2E8F0
$black0-color: #000000
$black2-color: #222222
$black3-color: #333333
$black5-color: #555555
$yellow0-color: #f9dd50
$yellow1-color: #ffdd2d
$yellow2-color: #ffcd33
$yellow3-color: #faa019
$blue1-color: #D0F4F2

*
    box-sizing: border-box
    margin: 0 
    padding: 0
    font-family: 'ISOCPEUR'
    // font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
    // font-family: 'Roboto', sans-serif

#root
    padding-bottom: 20px

body
    overflow-y: scroll
    background-color: $black2-color
    color: whitesmoke