.mortgage
    margin-top: 80px
    &__wrapper
        display: grid
        grid-template-columns: 35% 65%
        &_right
            padding-top: 100px
            font-size: 18px
            line-height: 32px
            color: silver
            span
                color: whitesmoke
    &__title
        font-size: 24px
        text-align: left
    &__range
        margin-top: 30px
        display: flex
        flex-direction: column
        justify-content: space-between
        height: 570px
    &__btn
        margin-top: 30px
    &__result
        &_title
            font-size: 20px
            margin-bottom: 30px
            span
                font-size: 24px
                font-weight: bold
        

@media (max-width: 991px)
    .mortgage__wrapper
        grid-template-columns: 50% 50%

@media (max-width: 767px)
    .mortgage
        &__wrapper
            grid-template-columns: 52% 48%
            &_right
                font-size: 16px
                &__result

@media (max-width: 575px)
    .mortgage
        &__wrapper
            display: flex
            flex-direction: column
            align-items: center
            &_right
                padding-top: 40px
        &__title
            text-align: center