.typing-loader{
    margin: 20px auto;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    -webkit-animation: line 1s linear infinite alternate;
   -moz-animation: line 1s linear infinite alternate;
   animation: line 1s linear infinite alternate;
}
@-webkit-keyframes line{
    0%{
       
        background-color: rgba(0,0,0, 1);
        box-shadow: 12px 0px 0px 0px rgba(255, 205, 51, 0.2), 
                    24px 0px 0px 0px rgba(255, 205, 51, 0.2);
                    
      }
    25%{ 
            background-color: rgba(255, 205, 51, 0.4);
            box-shadow: 12px 0px 0px 0px rgba(255, 205, 51,2), 
                    24px 0px 0px 0px rgba(255, 205, 51,0.2);
                    
    }
    75%{ background-color: rgba(255, 205, 51, 0.4);
        box-shadow: 12px 0px 0px 0px rgba(255, 205, 51,0.2), 
                    24px 0px 0px 0px rgba(255, 205, 51,2);
                   
      }
}

@-moz-keyframes line{
    0%{
       
        background-color: rgba(0,0,0, 1);
        box-shadow: 12px 0px 0px 0px rgba(255, 205, 51, 0.2), 
                    24px 0px 0px 0px rgba(255, 205, 51, 0.2);
                    
      }
    25%{ 
            background-color: rgba(255, 205, 51, 0.4);
            box-shadow: 12px 0px 0px 0px rgba(255, 205, 51, 2), 
                    24px 0px 0px 0px rgba(255, 205, 51, 0.2);
                    
    }
    75%{ background-color: rgba(255, 205, 51, 0.4);
        box-shadow: 12px 0px 0px 0px rgba(255, 205, 51, 0.2), 
                    24px 0px 0px 0px rgba(255, 205, 51, 2);
                   
      }
}

@keyframes line{
    0%{
       
        background-color: rgba(0,0,0, 1);
        box-shadow: 12px 0px 0px 0px rgba(255, 205, 51, 0.2), 
                    24px 0px 0px 0px rgba(255, 205, 51, 0.2);
                    
      }
    25%{ 
            background-color: rgba(255, 205, 51, 0.4);
            box-shadow: 12px 0px 0px 0px rgba(255, 205, 51, 2), 
                    24px 0px 0px 0px rgba(255, 205, 51, 0.2);
                    
    }
    75%{ background-color: rgba(255, 205, 51, 0.4);
        box-shadow: 12px 0px 0px 0px rgba(255, 205, 51, 0.2), 
                    24px 0px 0px 0px rgba(255, 205, 51, 2);
                   
      }
}