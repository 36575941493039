@import '../../styles/style.sass'
    
.header
    position: fixed
    top: 0
    width: 100%
    height: 60px
    z-index: 1000
    background-color: $yellow1-color
    &__menu
        height: 40px
        display: flex
        justify-content: space-around
        align-items: center
        margin: 10px 50px 0
        list-style-type: none
        padding-left: 0
        &_item
            font-size: 20px
            a
                display: contents
                padding: 0 30px
                font-size: 20px
                font-weight: bold
                &:link, &:visited
                    color: white
                    text-decoration: none
                    text-shadow: 1px 0 $yellow3-color,  0 -1px $yellow3-color, -1px 0 $yellow3-color, 0 1px $yellow3-color
                &:hover
                    text-shadow: none
                &.active
                    color: $black2-color
                    text-shadow: none

@media (max-width: 767px) // burger-menu
    .header
        &__nav 
            display: block
            position: absolute
            width: 100%
            background-color: $yellow1-color
            transition: all 0.4s ease
            left:-100%
            &.open-menu
                left: 0
        &__menu
            display: block
            height: unset
            &_item
                margin: 20px
                font-size: 14px
        &__menu-burger
            position: relative
            width: 40px
            height: 35px
            display: block
            margin: 10px
            z-index: 100
            cursor: pointer
            span, &:after, &:before
                height: 3px
                width: 100%
                position: absolute
                background: $black5-color
                margin: 0 auto
            span
                top: 16px
            &:after, &:before
                content: ''
            &:after
                bottom: 5px	
            &:before
                top: 5px
            &.open-menu
                span
                    opacity:0 
                    transition: 0.5s
                &:after
                    transform: rotate(-38deg)
                    bottom: 16px
                    transition: 0.4s
                &:before
                    transform: rotate(38deg)
                    top: 16px
                    transition: 0.4s