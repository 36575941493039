.modal-content
    background-color: #222222
    border-color: #444444

// .nav-link
//     color: #ffdd2d!important
//     &.active
//         background-color: #222222!important
//         color: silver!important
//         // color: #fab619!important
//         font-weight: bold
//         // border-color: #ffdd2d!important
//         border: none
//     &:hover
//         border-color: #222222!important

.nav-link
    color: rgba(198,201,216,.75)!important
    font-weight: bold
    &.active
        background-color: #222222!important
        color: #ffdd2d!important
        border-color: rgba(198,201,216,.75)!important