@import '../../styles/style.sass'
    
.chat
    margin: 40px auto
    width: 70%
    &__form
        margin: 0 auto
        width: 70%
        &_btn
            display: flex
            justify-content: end
    &__btn
        margin-top: 20px
        margin-bottom: 15px
        &_rmv
            position: fixed
            right: 30px
            bottom: 30px
    &__list
        margin-top: 20px
        width: 70%
        margin: 0 auto
        &_item
            margin-top: 15px
            padding: 10px
            font-size: 18px
            line-height: 32px
            color: $black5-color
            background-color: $yellow0-color
            text-align: right
            &.sender
                background-color: $blue1-color
                text-align: left

@media (max-width: 767px)
    .chat
        width: 85%

@media (max-width: 575px)
    .chat
        width: 100%
        &__list
            width: 80%
            &_item
                padding: 7px
                font-size: 15px
                line-height: 24px

@media (max-width: 425px)
    .chat__list
        width: 85%