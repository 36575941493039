@import '../../styles/style.sass'
    
.flat-detail
    color: whitesmoke
    &__date
        font-size: 14px
        line-height: 18px
        font-style: italic
        text-align: right
        color: silver
    &__wrapper
        display: grid
        grid-template-columns: 60% 40%
    &__info
        padding-left: 40px
        &_flat
            font-size: 22px
            font-weight: bold
        &_price
            margin-top: 13px
            font-size: 26px
            font-weight: bold
        &_subprice
            font-size: 16px
            color: silver
            margin-bottom: 14px
        &_info
            font-size: 16px
            color: silver
        &_icons
            margin-top: 50px
            text-align: left
            font-size: 20px
            position: relative
            &-item
                cursor: pointer
                &::after
                    position: absolute
                    background: $black2-color
                    color: $yellow1-color
                    content: attr(data-tooltip)
                    top: 35px
                    left: 0
                    font-size: 14px
                    opacity: 0
                    padding: 0
                    position: absolute
                    visibility: hidden
                    transition: all .6s ease-in-out
                &:nth-child(2)
                    margin-left: 20px
                    margin-right: 20px
                &:hover
                    color: $yellow1-color
                    &::after
                        opacity: 1
                        visibility: visible
    &__img
        &_tabs
            margin-top: 0
            margin-bottom: 15px
        img
            width: 100%
            height: 300px
            object-fit: contain
    &__link
        margin-top: 15px auto
        color: $yellow1-color
        font-style: italic
        text-decoration: none
        display: inline
        width: 90%
        text-align: center
        &:hover
            color: $yellow3-color


@media (max-width: 991px)
    .flat-detail
        &__wrapper
            grid-template-columns: 50% 50%
        &__info
            padding-left: 30px
            &_price
                font-size: 24px
            &_subprice
                font-size: 14px
        &__img
            img
                height: 200px

@media (max-width: 530px)
    .flat-detail
        &__wrapper
            display: block
        &__info
            margin-top: 20px
            &_icons
                margin-top: 20px
                margin-bottom: 10px
        &__date
            font-size: 12px