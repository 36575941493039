.reserve
    width: 90%
    margin: 50px auto
    &__title
        display: flex
        justify-content: center
        font-size: 28px
        font-weight: bold
        width: 70%
        margin: 0 auto 30px
        &_price
            margin-left: 70px
            margin-right: 30px
        &_subprice
            font-weight: normal
    &__plans
        display: grid
        grid-template-columns: repeat(2, 47%)
        grid-template-rows: 350px
        column-gap: 6%
        background-color: #fff
        img
            width: 100%
            height: 100%
            object-fit: contain
    &__info
        margin: 30px auto
        width: 300px
        font-size: 20px
        color: silver
        span
            font-weight: bold
            color: #EAECEE
        &_date
            font-size: 16px
        &_link
            color: silver
            text-decoration: none
            &:hover
                color: #EAECEE
                font-weight: bold
    &__slider
        width: 50%
        margin: 0 auto
    &__btn
        position: fixed
        right: 30px
        bottom: 30px

@media (max-width: 1240px)
    .reserve
        &__title
            font-size: 26px
            width: 75%

@media (max-width: 1100px)
    .reserve
        &__title
            width: 90%

@media (max-width: 900px)
    .reserve
        margin-top: 30px
        &__title
            font-size: 24px
            width: 100%

@media (max-width: 767px)
    .reserve
        width: 100%
        margin-top: 20px
        &__title
            margin-bottom: 15px
            &_price
                margin-left: 25px
                margin-right: 20px
        &__plans
            grid-template-columns: repeat(2, 49%)
            grid-template-rows: 300px
            column-gap: 2%

@media (max-width: 625px)
    .reserve
        &__title
            flex-direction: column
            align-items: center
            font-size: 26px
            &_price
                margin: 0
        &__plans
            grid-template-columns: 100%
            grid-template-rows: 350px 350px
            row-gap: 20px
        &__slider
            width: 90%